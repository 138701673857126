import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import React, { Component, Suspense, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import { HashRouter, Route, Routes, Navigate } from 'react-router-dom'
import './scss/style.scss'
import './mystyle.css'
import './responsive.css'
import './styleForm.css'
import './styleTable.css'
import './jai.css'
import GlobalProvider from './context'
import { GlobalContext } from './context/index'
import { USERDATA, USERTOKEN, COMPANY_ID } from './constants/localstorage'
import { PROFILE, COMPANY_CHANGE, LOGOUT } from './constants/reducers'
import { BarLoader } from 'react-spinners'

const loading = (
  <div
    style={{
      width: '100vw',
      height: '100vh',
      justifyContent: 'center',
      alignItems: 'center',
    }}
    className="d-flex sk-double-bounce"
  >
    <div
      style={{
        marginTop: '-4%',
      }}
      className="d-flex flex-column"
    >
      <div className="d-flex justify-content-center">
        <img
          width={200}
          height={200}
          style={{ objectFit: 'contain' }}
          src="https://account.kpostindia.com/assets/image/logo/klogo.png"
        />
      </div>
      <div className="pt-2">
        <BarLoader
          width={300}
          height={9}
          color={`#3995c8`}
          speedMultiplier={0.5}
          cssOverride={{
            borderRadius: '10px',
          }}
        />
      </div>
    </div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Forget = React.lazy(() => import('./views/pages/Forget/forget'))
const Password = React.lazy(() => import('./views/pages/Password/password'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const AppRoutes = () => {
  const { state, dispatch } = React.useContext(GlobalContext)
  const { isAuthenticated } = state
  const [isLoading, setIsLoading] = useState(true)

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split('.')[1]))
    } catch (e) {
      return null
    }
  }

  const fetchData = async () => {
    const userData = await localStorage.getItem(USERDATA)
    const userToken = await localStorage.getItem(USERTOKEN)
    const companyData = await localStorage.getItem(COMPANY_ID)
    // alert(JSON.stringify(companyData))
    // console.log('companyData', companyData)

    if (JSON.parse(userData) && userToken) {
      const decodedJwt = parseJwt(userToken)
      // alert(JSON.stringify(decodedJwt))
      if (decodedJwt.exp * 1000 < Date.now()) {
        dispatch({
          type: LOGOUT,
        })
        localStorage.clear()
        toast.error('Session Has Been Ended , Please ReLogin')
      } else {
        dispatch({
          type: PROFILE,
          payload: { ...JSON.parse(userData), userToken: userToken },
        })
      }
    } else {
      dispatch({
        type: LOGOUT,
        payload: { ...JSON.parse(userData), userToken: userToken },
      })
    }
    // if (companyData) {
    //   // console.log(companyData, 'LScompanyData')
    //   dispatch({
    //     type: COMPANY_CHANGE,
    //     payload: { companyId: companyData },
    //   })
    // }
    // setTimeout(() => {
    setIsLoading(false)
    // }, 3000)
  }

  React.useEffect(() => {
    fetchData()
  }, [])

  if (isLoading) {
    return (
      <div
        style={{
          width: '100vw',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        className="d-flex sk-double-bounce"
      >
        <div
          style={{
            marginTop: '-4%',
          }}
          className="d-flex flex-column"
        >
          <div className="d-flex justify-content-center">
            <img
              width={200}
              height={200}
              style={{ objectFit: 'contain' }}
              src="https://account.kpostindia.com/assets/image/logo/klogo.png"
            />
          </div>
          <div className="pt-2">
            {/* #42baea */}
            <BarLoader
              width={300}
              height={9}
              color={`#3995c8`}
              // speedMultiplier={1}
              cssOverride={{
                borderRadius: '10px',
              }}
            />
          </div>
        </div>
        {/* <div className="spinner-border text-warning" role="status"></div> */}
      </div>
    )
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <HashRouter>
        <Suspense fallback={loading}>
          {isAuthenticated ? (
            <Routes>
              <Route exact path="/login" name="Login Page" element={<Navigate to={'/'} />} />
              <Route path="*" name="Home" element={<DefaultLayout />} />
              {/* <Route exact path="/forget" name="Login Page" element={<Forget />} /> */}
            </Routes>
          ) : (
            <Routes>
              <Route exact path="/" name="Login" element={<Login />} />
              <Route exact path="/login" name="Login Page" element={<Login />} />
              <Route exact path="/forget" name="Forgot Password" element={<Forget />} />
              <Route exact path="/resetpassword" name="Confirm Password" element={<Password />} />
              <Route exact path="/register" name="Register Page" element={<Register />} />
              <Route exact path="/500" name="Page 500" element={<Page500 />} />
              <Route exact path="*" name="Page 404" element={<Navigate to={'/login'} />} />
              <Route exact path="*" name="Page 404" element={<Page404 />} />
            </Routes>
          )}
        </Suspense>
      </HashRouter>
    </React.Fragment>
  )
}

const App = () => {
  return (
    <GlobalProvider>
      <AppRoutes />
      {/* <AutoLogoutIfUnAuthorized /> */}
    </GlobalProvider>
  )
}

export default App
