const ENV = 'oldlive'

const URLS = {
  amresh: 'http://192.168.2.33:8080/v1',
  Sriram: 'http://192.168.2.30:2505/api/v2',
  oldlive: 'https://planningapi.kpost.in/api/v1',
  Newlive: 'https://testplanningapi.kpost.in/api/v2',
}

export const URL = URLS[ENV]
